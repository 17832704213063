<template>
    <v-container class="down-top-padding pa-0" fluid id="container">
        <v-dialog
      v-model="drawer"
      fullscreen

      transition="dialog-bottom-transition"
      >
<v-card v-if="docView">
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="drawer = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{docView.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
                    <div v-if="docView && docView.src">
                        <iframe v-if='docView.ext=="docx"' :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + drawer.src" width="100%" :height="getFileHeight()" frameborder='0'>This is an embedded <a target='_blank' href='https://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='https://office.com/webapps'>Office Online</a>.</iframe>
                        <object  v-if='docView.ext=="pdf"'><embed :src="docView.src" width="100%"  :height="getFileHeight()" /></object>
                        <v-img v-if='docView.ext!="docx" && docView.ext!="pdf"' :src="docView.src" contain :max-height="getFileHeight()"/>
                    </div>
      </v-card>
        </v-dialog>
        <objectView v-if="object && stations.length>0" :mobile="true" :docView.sync="docView" :object.sync="object" :stations="stations" :editMod="false"/>
    </v-container>
</template>

<script>
    import objectView from '../serviceArea/objectView'
    export default {
        name: "index",
        data: () => ({
            docView:null,
            timer:null,
            drawer:null,
            stations:[],
            object:{}
            }),
            components:{
                objectView
            },
            watch:{
                docView(){
                    this.drawer=true
                }
            },
        methods: {
            getFileHeight(){
                    const height = window.innerHeight || document.documentElement.clientHeight ||
                        document.body.clientHeight;  
                    return height-75
                },
            getInfo() {
                this.$http.get('area/info?key=' + this.$route.params.key).then((response) => {
                    this.object = response.data
                    this.stations=[response.data.station]
                })
            },
            getTrackTargetId() {
                this.$http.get('area/track-target-id?key=' + this.$route.params.key).then((response) => {
                    if(response.data!=this.object.id){
                        this.getInfo()
                    }
                })
            }
        }, beforeDestroy: function () {
            clearTimeout(this.timer)
        },
        mounted() {
            this.getInfo()
            this.timer = setInterval(() => {
                this.getTrackTargetId()
            }, 5000)
        }

    }
</script>